import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from '~/lib/react-toast'
import { BeWizrMeeting, BeWizrMeetingOccurrence } from '~/models'
import { memo, observer } from '~/ui/component'
import { HBox, Label, Panel, PushButton, SVG, TextBlock, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { Form, SubmitResult } from '~/ui/form'
import { createUseStyles, layout } from '~/ui/styling'
import { BeWizrMeetingEnrollmentFormModel } from './BeWizrMeetingEnrollmentFormModel'
import BeWizrMeetingOccurrenceDateTimeView from './BeWizrMeetingOccurrenceDateTimeView'
import BeWizrMeetingOccurrencesList from './BeWizrMeetingOccurrencesList'

export interface Props {
  meeting:    BeWizrMeeting
  occurrence?: BeWizrMeetingOccurrence
}

const BeWizrMeetingEnrollmentForm = observer('BeWizrMeetingEnrollForm', (props: Props) => {

  const {meeting, occurrence} = props

  const model = React.useMemo(
    () => new BeWizrMeetingEnrollmentFormModel(meeting.id, occurrence?.id),
    [meeting.id, occurrence?.id],
  )

  const selectedOccurence = model.occurrence != null ? model.document.resolveOccurrence(model.occurrence) : null
  const action            = selectedOccurence?.isEnrolled ? 'cancel' : 'enroll'

  const [t] = useTranslation('bewizr')

  const selectOccurrence = React.useCallback((occurrence: BeWizrMeetingOccurrence) => {
    if (model.occurrence === occurrence.id) {
      model.occurrence = null
    } else {
      model.occurrence = occurrence.id
    }
  }, [model])

  const toastErrorOrSucces = React.useCallback((result: SubmitResult) => {
    if (result.status === 'ok') {
      Toast.success({
        ...t(`meetings.enrollment.${result.data.action}.notification`, {meeting}),
      })
    } else if (result.status === 'error') {
      const httpStatus = 'status' in result.error ? result.error.status : null
      Toast.error({
        ...t([`errors:${httpStatus}`, 'errors:unknown']),
      })
    }
  }, [t, meeting])

  //------
  // Rendering

  function render() {
    return (
      <Form model={model} afterSubmit={toastErrorOrSucces}>
        {occurrence == null ? renderOccurrencesList() : renderOccurrenceSummary()}
        <PushButton
          icon={action === 'cancel' ? 'cross' : undefined}
          enabled={model.maySubmit}
          working={model.submitting}
          caption={t(`meetings.enrollment.${action}.caption`)}
          submit
        />
      </Form>
    )
  }

  function renderOccurrencesList() {
    return (
      <VBox gap={layout.padding.s}>
        <TextBlock small dim>
          {t('meetings.enrollment.instruction')}
        </TextBlock>
        <BeWizrMeetingOccurrencesList
          meetingID={meeting.id}
          selectedOccurrenceID={model.occurrence}
          scope='open'
          requestSelect={selectOccurrence}
        />
      </VBox>

    )
  }

  function renderOccurrenceSummary() {
    if (occurrence == null) { return null }

    const {location, isOnline} = occurrence
    return (
      <VBox gap={layout.padding.s}>
        <TextBlock small dim>
          {t(`meetings.enrollment.${action}.summary`)}
        </TextBlock>
        <Panel>
          <BeWizrMeetingEnrollmentSummaryItem icon='calendar'>
            <BeWizrMeetingOccurrenceDateTimeView
              small
              occurrence={occurrence}
              timeFormat={false}
              dateFormat='d LLLL yyyy'
            />
          </BeWizrMeetingEnrollmentSummaryItem>
          <BeWizrMeetingEnrollmentSummaryItem icon='clock'>
            <BeWizrMeetingOccurrenceDateTimeView
              small
              occurrence={occurrence}
              dateFormat={false}
            />
          </BeWizrMeetingEnrollmentSummaryItem>
          {isOnline && (
            <BeWizrMeetingEnrollmentSummaryItem icon='webcast'>
              <Label small>{t('meetings.online')}</Label>
            </BeWizrMeetingEnrollmentSummaryItem>
          )}
          {location != null && (
            <BeWizrMeetingEnrollmentSummaryItem icon='map-pin'>
              <TextBlock small flex>
                {`${location.title}, ${location.addressLine}, ${location.addressZip} ${location.addressCity}`}
              </TextBlock>
            </BeWizrMeetingEnrollmentSummaryItem>
          )}
        </Panel>
      </VBox>
    )
  }

  return render()

})

export default BeWizrMeetingEnrollmentForm

const useStyles = createUseStyles(theme => ({
  BeWizrMeetingEnrollmentSummaryItem: {
    '&:not(:last-child)': {
      borderBottom: [1, 'solid', theme.border.dimmer],
    },
  },
}))

interface BeWizrMeetingEnrollmentSummaryItem {
  icon:     SVGName
  children: React.ReactNode
}

const BeWizrMeetingEnrollmentSummaryItem = memo('BeWizrMeetingEnrollmentSummaryItem', (props: BeWizrMeetingEnrollmentSummaryItem) => {

  const {icon, children} = props

  const $ = useStyles()

  return (
    <HBox classNames={$.BeWizrMeetingEnrollmentSummaryItem} gap={layout.padding.inline.l} padding={layout.padding.inline.l}>
      <SVG name={icon} size={layout.icon.m}/>
      {children}
    </HBox>
  )

})